<template>
    <div class="lay_container">
        <Header @parterr="parterr" :upadteQuotaStatus="getUpadteQuotaStatus"></Header>
        <div class="cont_m">
          <div class="max_cont_width flex_box">
          <Menu :percentageerr="percentageerr" :menuInfos="menuInfos" @getCurLink="getKey"></Menu>
          <div class="right_box">
            <router-view @upadteQuota="getUpdateQuato"/>
          </div>
        </div>
    </div>
    <Footer></Footer>
</div>
</template>
<script>
import { getDashboard} from '@/api/userApi.js'
import Menu from './components/leftMenu.vue'
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
    components: { Header,Footer,Menu},
    data() {
        return {
            getUpadteQuotaStatus:false,
            dashboadObj:{},
            menuInfos:[],
            percentageerr:false
        }
    },
    computed: {
    },
    created() {
        this.dashboadInit()
    },
    methods: {
        parterr(value){
            console.log(`value`, value);
            this.percentageerr=value
        },
        getUpdateQuato(val){
            this.getUpadteQuotaStatus=val
        },

        //获取菜单权限
        dashboadInit() {
            getDashboard().then((result) => {
                if (result && result.code) {
                    this.menuInfos=[]
                    let data = result.data
                    //菜单权限
                    let LoginglanceType=''
                    let UsagelistType=''
                    let OrderlistType=''
                    let RechargelistType=''
                    let loginDurationType=''
                    let menuList=data.roleInfos[0].sysMenuInfos
                    menuList.forEach(item => {
                        if(item.title=="Login at a glance"){
                        LoginglanceType=item.type
                        }
                        else if(item.title=="Usage list"){
                        UsagelistType=item.type
                        }
                        else if(item.title=="Order List"){
                        OrderlistType=item.type
                        }
                        else if(item.title=="Recharge list"){
                        RechargelistType=item.type
                        }
                        else if(item.title=="List of login duration and times"){
                        loginDurationType=item.type
                        }
                        else{
                            this.menuInfos.push(item)
                        }
                    });
                    this.dashboadObj = {
                        LastLoginDuration: data.lastLogDur,
                        TotalLogins: data.totalLogins,
                        AverageDuration: data.avgDuration,
                        LastViewedQuantity: data.lastView,
                        QuantityOfThisMonth: data.monthView,
                        TotalViews: data.totalView,
                        LastUpdatedQuantity: data.lastQua,
                        UpdateQuantityThisMonth: data.monthQua,
                        TotalUpdates: data.totalUpdate,
                        LastRechargeAmount: data.lastAmount,
                        AccumulatedRechargeAmount: data.rechAmount,
                        RemainingQuota: data.remainQuota,
                        tab1:LoginglanceType,
                        tab2:UsagelistType,
                        tab3:OrderlistType,
                        tab4:RechargelistType,
                        tab5:loginDurationType,
                    }
                }
            })
        },

        //刷新菜单
        getKey(val){
            if(val===0){
                this.dashboadInit()
            }
        }
    }
}
</script>
<style scoped>
.cont_m{
    margin-top: 170px;
    margin-bottom: 80px;
}
</style>