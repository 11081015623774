<template>
    <div class="wrap">
        <div class="left_menu">
            <ul class="de_menu_list">
                <li class="de_menu_item" v-for="(item, index) in menuTitleList" :key="index">
                    <div class="flex_center_between_box" v-if="item.type != 0">
                        <router-link class="de_menu_item_a" :to="item.url" @click.native="(linkActive = index), (curlinkActive = index)">
                            <div class="title" :class="['ic_' + index, linkActive == index && linkActive != -1 ? 'subOn' : '']">
                                <span :class="{ parnterr: item.title == 'Add Credit' && percentageerr }">{{ item.title }}</span>
                            </div>
                        </router-link>
                        <div class="show_ic" @click="showSubMenu(item, index)" v-if="item.result.length != 0" :class="{ open: item.disBol == true, close: item.disBol == false }"></div>
                    </div>

                    <transition name="sub-comments">
                        <ul class="de_menu_sublist" v-if="item.disBol">
                            <li class="de_menu_subitem" v-for="(subItem, i) in item.result" :key="i">
                                <div @click="linkActive = index" v-if="subItem.type != 0">
                                    <router-link class="de_menu_subitem_a" :to="subItem.url">
                                        {{ subItem.name }}
                                    </router-link>
                                </div>
                            </li>
                        </ul>
                    </transition>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        menuInfos: {
            type: Array,
        },
        percentageerr: {
            type: Boolean,
        },
    },
    data() {
        return {
            linkActive: -1,
            menuTitleList: [
                // {
                //     id: '1',
                //     title: 'Dashboard',
                //     url: '/account/dashboard',
                //     disBol: true,
                //     result: [],
                // },
                // {
                //     id: '2',
                //     title: 'Add Credit',
                //     url: '/account/addCredit',
                //     disBol: true,
                //     result: [],
                // },
                // {
                //     id: '3',
                //     title: 'My Database',
                //     url: '/account/myDatabase',
                //     disBol: true,
                //     result: [],
                // },
                // {
                //     id: '4',
                //     title: 'Portfolio',
                //     url: '/account/portfolio',
                //     disBol: true,
                //     result: [{ id: '4-1', name: 'My tag', url: '/account/myTag' }],
                // },
                // {
                //     id: '5',
                //     title: 'Account',
                //     url: '/account/accountSettings',
                //     disBol: true,
                //     result: [
                //         { id: '5-1', name: 'Contact Information', url: '/account/contactInformation' },
                //         { id: '5-2', name: 'Alternate Contacts', url: '/account/alternateContacts' },
                //     ],
                // },
                // {
                //     id: '6',
                //     title: 'Organization',
                //     url: '/account/organization',
                //     disBol: true,
                //     result: [],
                // },
                // {
                //     id: '7',
                //     title: 'Quota',
                //     url: '/account/quota',
                //     disBol: true,
                //     result: [],
                // },
                // {
                //     id: '8',
                //     title: 'Billing',
                //     url: '/account/billing',
                //     disBol: true,
                //     result: [],
                // },
                // {
                //     id: '9',
                //     title: 'FAQ',
                //     url: '/account/faq',
                //     disBol: true,
                //     result: [],
                //},
            ],
            list: [],
            list2: [],
            curlinkActive: -1,
        };
    },
    // 监听,当路由发生变化的时候执行
    watch: {
        $route: 'getPath',
        menuInfos: {
            handler(newVal) {
                if (newVal.length > 0) {
                    this.list = newVal;
                    this.getMenu();
                }
            },
        },
        percentageerr: {
            handler(newVal) {
                // console.log(`newVal`, newVal);
            },
        },
        curlinkActive: {
            handler(newVal) {
                if (newVal == 0) {
                    this.linkClick();
                }
            },
        },
    },
    computed: {},
    mounted() {
        console.log(this.$parent);
    },

    created() {
        this.getPath();
    },
    methods: {
        //折叠面板
        showSubMenu(el, index) {
            if (el.disBol == false) {
                el.disBol = true;
                return;
            }
            if (el.disBol == true) {
                el.disBol = false;
                return;
            }
        },
        //当路由发生变化的时候执行
        getPath() {
            const route = this.$route.path;
            const name = route.split('/account/')[1];
            if (name == 'myTag' || name == 'portfolio') {
                this.linkActive = 3;
            } else if (name == 'contactInformation' || name == 'alternateContacts' || name == 'accountSettings') {
                this.linkActive = 4;
            }
        },

        //获取菜单权限 index->menu
        // getMenu() {
        //     let list = this.list;
        //     let arr = [];
        //     let accArr=[]
        //     let mytag=[]

        //     for (let index = 0; index < list.length-1; index++) {
        //         const item = list[index];
        //         let obj ={}
        //         if (item.title.replace(/\s/g, '').toLowerCase() == 'portfolio') {
        //             let subObj = { name: item.title,type: item.type, url: '/account/contactInformation' };
        //             accArr.push(subObj);
        //         }
        //         else if (item.title.replace(/\s/g, '').toLowerCase() == 'alternateContacts') {
        //             let subObj = { name: item.title,type: item.type, url: '/account/alternateContacts' };
        //             accArr.push(subObj);
        //         }
        //         else if (item.title.replace(/\s/g, '').toLowerCase() == 'mytag') {
        //             let subObj = { name: item.title,type: item.type, url: '/account/myTag' };
        //             mytag.push(subObj);
        //         }
        //         else{
        //             obj = {
        //                 title: item.title,
        //                 url: '/account/' + item.title.replace(/\s/g, '').toLowerCase(),
        //                 disBol: true,
        //                 result: [],
        //                 type: item.type,
        //             };
        //             arr.push(obj);
        //         }
        //     }
        //     console.log('------', arr);
        //     this.list2 = arr;
        //     this.getMenu1()
        // },
        getMenu() {
            let list = this.list;
            let that = this;
            var num1 = '';
            var num2 = '';
            var num3 = '';
            let accArr = [];
            let mytag = [];
            let menuList = [];
            let billi = [];
            that.$nextTick(() => {
                for (let index = 0; index < list.length; index++) {
                    const item = list[index];
                    let title = item.title.replace(/\s/g, '').toLowerCase();
                    if (title == 'dashboard') {
                        let obj = {
                            id: item.id,
                            title: 'Dashboard',
                            url: '/account/dashboard',
                            disBol: true,
                            result: [],
                            type: item.type,
                        };
                        menuList.push(obj);
                    } else if (title == 'addcredit') {
                        let obj = {
                            id: item.id,
                            title: 'Add Credit',
                            url: '/account/addCredit',
                            disBol: true,
                            result: [],
                            type: item.type,
                        };
                        menuList.push(obj);
                    } else if (title == 'mydatabase') {
                        let obj = {
                            id: item.id,
                            title: 'My Database',
                            url: '/account/myDatabase',
                            disBol: true,
                            result: [],
                            type: item.type,
                        };
                        menuList.push(obj);
                    } else if (title == 'portfolio') {
                        let obj = {
                            id: item.id,
                            title: 'Portfolio',
                            url: '/account/portfolio',
                            disBol: true,
                            result: [],
                            type: item.type,
                        };
                        num1 = index;
                        menuList.push(obj);
                    } else if (title == 'mytag') {
                        let obj = {
                            id: item.id,
                            name: 'My tag',
                            url: '/account/myTag',
                            type: item.type,
                        };
                        mytag.push(obj);
                        menuList.push('');
                    } else if (title == 'account') {
                        let obj = {
                            id: item.id,
                            title: 'Account',
                            url: '/account/accountSettings',
                            disBol: true,
                            result: [],
                            type: item.type,
                        };
                        num2 = index;
                        menuList.push(obj);
                    } else if (title == 'contactinformation') {
                        let obj = {
                            id: item.id,
                            name: 'Contact Information',
                            url: '/account/contactInformation',
                            type: item.type,
                        };
                        accArr.push(obj);
                        menuList.push('');
                    } else if (title == 'alternatecontacts') {
                        let obj = {
                            id: item.id,
                            name: 'Alternate Contacts',
                            url: '/account/alternateContacts',
                            type: item.type,
                        };
                        accArr.push(obj);
                        menuList.push('');
                    } else if (title == 'organization') {
                        let obj = {
                            id: item.id,
                            title: 'Organization',
                            url: '/account/organization',
                            disBol: true,
                            result: [],
                            type: item.type,
                        };
                        menuList.push(obj);
                    } else if (title == 'quota') {
                        let obj = {
                            id: item.id,
                            title: 'Quota',
                            url: '/account/quota',
                            disBol: true,
                            result: [],
                            type: item.type,
                        };
                        menuList.push(obj);
                    } else if (title == 'billing') {
                        let obj = {
                            id: item.id,
                            title: 'Billing',
                            url: '/account/billing',
                            disBol: true,
                            result: [],
                            type: item.type,
                        };
                        let obj1 = {
                            id: item.id + 1,
                            name: 'Billing Details',
                            url: '/account/billinginformation',
                            type: item.type,
                        };
                        let obj2 = {
                            id: item.id + 2,
                            name: 'Billing Information',
                            url: '/account/billinginformationtwo',
                            type: item.type,
                            // img: require('../assets/img/user-icons/4.png'),
                        };
                        num3 = index;
                        billi.push(obj1);
                        billi.push(obj2);
                        menuList.push(obj);
                    } else if (title == 'faq') {
                        let obj = {
                            id: item.id,
                            title: 'FAQ',
                            url: '/account/faq',
                            disBol: true,
                            result: [],
                            type: item.type,
                        };
                        menuList.push(obj);
                    }
                }
                menuList[num1].result = mytag;
                menuList[num2].result = accArr;
                menuList[num3].result = billi;
                that.menuTitleList = menuList.filter(item => item !== '');
            });
        },

        linkClick() {
            this.$emit('getCurLink', this.curlinkActive);
        },
    },
};
</script>

<style scoped>
.parnterr {
    color: red !important;
}
.left_menu {
    padding: 20px 20px 20px 20px;
}
.de_menu_list .menu_ic {
    height: 20px;
    margin-right: 10px;
}
.de_menu_list .show_ic {
    width: 30px;
    height: 30px;
    text-align: right;
}
.de_menu_list .show_ic img {
    width: 10px;
}
.de_menu_item {
    margin-bottom: 10px;
}
.de_menu_subitem_a,
.de_menu_item_a {
    display: inline-block;
    color: #8497ab;
}
.de_menu_item_a {
    font-size: 16px;
}
.de_menu_item_a .title {
    padding: 10px 10px 10px 10px;
    background-size: 20px 20px;
    background-position: 10px center;
    background-repeat: no-repeat;
}
.de_menu_subitem div {
    padding-left: 31px;
}
.de_menu_subitem_a {
    font-size: 14px;
    padding: 10px;
}
.de_menu_item_a.router-link-exact-active.router-link-active .title,
.de_menu_item_a .subOn {
    font-family: 'Arial Bold';
    color: #022955;
}
.de_menu_item_a.router-link-exact-active.router-link-active .title,
.de_menu_subitem_a.router-link-exact-active.router-link-active {
    background-color: #ebf2ff;
    border-radius: 14px;
    color: #022955;
}
/* .de_menu_item_a .title.ic_0{
    background-image: url(../../../assets/img/account/menu_ic1_2.png);
}
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_0{
    background-image: url(../../../assets/img/account/menu_ic1_1.png);
}
.de_menu_item_a .title.ic_1{
    background-image: url(../../../assets/img/account/menu_ic2_2.png);
}
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_1{
    background-image: url(../../../assets/img/account/menu_ic2_1.png);
}
.de_menu_item_a .title.ic_2{
    background-image: url(../../../assets/img/account/menu_ic3_2.png);
}
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_2{
    background-image: url(../../../assets/img/account/menu_ic3_1.png);
}
.de_menu_item_a .title.ic_3{
    background-image: url(../../../assets/img/account/menu_ic4_2.png);
}
.de_menu_item_a .title.ic_3.subOn,
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_3{
    background-image: url(../../../assets/img/account/menu_ic4_1.png);
}

.de_menu_item_a .title.ic_4{
    background-image: url(../../../assets/img/account/menu_ic5_2.png);
}
.de_menu_item_a .title.ic_4.subOn,
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_4{
    background-image: url(../../../assets/img/account/menu_ic5_1.png);
}
.de_menu_item_a .title.ic_5{
    background-image: url(../../../assets/img/account/menu_ic6_2.png);
}
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_5{
    background-image: url(../../../assets/img/account/menu_ic6_1.png);
}
.de_menu_item_a .title.ic_6{
    background-image: url(../../../assets/img/account/menu_ic7_2.png);
}
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_6{
    background-image: url(../../../assets/img/account/qian.png);
}
.de_menu_item_a .title.ic_7{
    background-image: url(../../../assets/img/account/menu_ic7_2.png);
}
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_7{
    background-image: url(../../../assets/img/account/menu_ic7_1.png);
}
.de_menu_item_a .title.ic_8{
    background-image: url(../../../assets/img/account/menu_ic8_2.png);
}
.de_menu_item_a.router-link-exact-active.router-link-active .title.ic_8{
    background-image: url(../../../assets/img/account/menu_ic8_1.png);
} */

/* 折叠面板 */
.sub-comments-leave-active,
.sub-comments-enter-active {
    transition: max-height 0.25s ease;
}
.sub-comments-enter,
.sub-comments-leave-to {
    max-height: 0;
}
.sub-comments-enter-to,
.sub-comments-leave {
    max-height: 200px;
}
.de_menu_sublist {
    will-change: height;
    overflow: hidden;
}
.close,
.open {
    transform-origin: center;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
}
.open {
    background-image: url(../../../assets/img/search/menu_ic2.png);
}
.close {
    background-image: url(../../../assets/img/search/menu_ic1.png);
}
</style>
